import request from '@/utils/request'

//-------------报表管理---------
//商户每天获取积分
export function merchantReportList(data) {
    return request({
        url: '/MA_statistic/shopPointStatistic',
        method: 'post',
        params: data
    })
}

//最近一个月积分统计
export function systemReportList(data) {
    return request({
        url: '/MA_statistic/monthPointStatistic',
        method: 'post',
        params: data
    })
}

//社区社员及积分情况
export function communityVolPointsList(data) {
    return request({
        url: '/screen/communityVolunteerPoints',
        method: 'post',
        params: data
    })
}

//沿江街道“爱社区·共同富裕社”各社区推进情况
export function promotionSituationList(data) {
    return request({
        url: '/screen/communityVolunteerPointsNew',
        method: 'post',
        params: data
    })
}

//积分排行报表
export function pointsRankingList(data) {
    return request({
        url: '/screen/pointRank',
        method: 'post',
        params: data
    })
}

//党员排行报表
export function partyMemberRankingList(data) {
    return request({
        url: '/screen/politicalReport',
        method: 'post',
        params: data
    })
}

//党员排行 导出表格
export function partyMemberRankExportExcel(data) {
    return request({
        url: '/screen/exportPoliticalReport',
        method: 'post',
        params: data,
        responseType: 'arraybuffer'
    })
}

//社员信息汇总
export function volSummaryList(data) {
    return request({
        url: '/screen/volunteerSummary',
        method: 'post',
        params: data
    })
}

//党员社员信息汇总
export function volCpcSummaryList(data) {
    return request({
        url: '/screen/cpcVolunteersSummary',
        method: 'post',
        params: data
    })
}

//街道商户情况
export function shopConsumeOverviewList(data) {
    return request({
        url: '/screen/shopConsumeOverview',
        method: 'post',
        params: data
    })
}

//街道商户情况
export function communityConsumeOverviewList(data) {
    return request({
        url: '/screen/communityConsumeOverview',
        method: 'post',
        params: data
    })
}

//导出表格
export function exportExcel(data) {
    return request({
        url: '/screen/exportExcel',
        method: 'post',
        params: data,
        responseType: 'arraybuffer'
    })
}

//特殊困难群体排行报表
export function healthMemberRankingList(data) {
    return request({
        url: '/screen/healthReport',
        method: 'post',
        params: data
    })
}

//特殊困难群体排行 导出表格
export function healthMemberRankExportExcel(data) {
    return request({
        url: '/screen/exportHealthReport',
        method: 'post',
        params: data,
        responseType: 'arraybuffer'
    })
}